import sha1 from 'js-sha1'

export const csvToArray = (str, delimiter = ',') => {
  // slice from sto Babel config file detected forart of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter)

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const tmp_rows = str.slice(str.indexOf('\n') + 1).split('\n')

  const rows = tmp_rows.map((tmprow) => {
    const dati = tmprow.split(',')
    const row = {}
    dati.map((dato, index) => {
      if (headers[index] != undefined) {
        row[headers[index]] = dato
      } else {
        row[headers[index]] = ''
      }
      return dato
    })
    return row
  })

  return {
    headers: headers,
    rows: rows
  }
}

export const creaHash = (token) => {
  const hash = sha1(token.token_address + token.token_id + token.owner_of)
  return hash
}

export const downloadCSV = (csv, filename) => {
  let csvFile = null
  let downloadLink = null
  csvFile = new Blob([csv], { type: 'text/csv' })
  downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}
export const b64toBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

export const downloadImage = (data, filename) => {
  let file = null
  let downloadLink = null
  file = new Blob([data], { type: 'image/jpeg' })
  downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(file)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}

export const downloadPDF = (pdf, filename) => {
  let pdfFile = null
  let downloadLink = null
  pdfFile = new Blob([pdf], { type: 'application/pdf' })
  downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(pdfFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}

export const base64ToHex = (str) => {
  const raw = atob(str)
  let result = ''
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16)
    result += (hex.length === 2 ? hex : '0' + hex)
  }
  return result.toUpperCase()
}

export const readFileSync = async (file) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  const result = await new Promise((resolve, reject) => {
    reader.onload = function (event) {
      resolve(reader.result)
    }
  })
  return result
}
