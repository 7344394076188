import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { chainId } from '@/config.js'
import Moralis from './plugins/moralis.js'
import './css/main.css'
import 'flowbite';

(async () => {
  /* App style */
  store.dispatch('setStyle', 'basic')

  /* Default title tag */
  const defaultDocumentTitle = 'Pandolfini NFT PLATFORM'

  const checkRoute = (to) => {
    const user = store.state.user
    let isOperator = false
    let isMinter = false
    if (user != false) {
      isOperator = user.get('isOperator')
      isMinter = user.get('isMinter')
    }
    if (to.name != 'login' && user == false) {
      return 'login'
    }
    if (to.name != 'seller' && (!isOperator && !isMinter) && user != false) {
      return 'seller'
    }
    if (to.name == 'mint' && !isMinter) {
      return 'login'
    }

    if (to.name == 'dashboard' && (!isOperator && !isMinter)) {
      return 'login'
    }

    return false
  }
  router.beforeEach(async (to, _, next) => {
    const route = checkRoute(to)
    if (route != false) {
      next(route)
      return
    }
    next()
  })

  router.afterEach(to => {
    /* Set document title from route meta */
    if (to.meta && to.meta.title) {
      document.title = `${to.meta.title} — ${defaultDocumentTitle}`
    } else {
      document.title = defaultDocumentTitle
    }
    /* Full screen mode */
    store.dispatch('fullScreenToggle', !!to.meta.fullScreen)
  })
  try {
    const isWeb3Active = Moralis.ensureWeb3IsInstalled()
    if (!isWeb3Active) {
      await Moralis.enableWeb3()
    }
    const currentChain = await Moralis.getChainId()
    if (currentChain != chainId) {
      await Moralis.switchNetwork(chainId)
    }
  } catch (err) {
    alert(err.message)
  }

  const app = createApp(App)
  app.use(router)
  app.use(store)
  app.provide('$moralis', Moralis)
  app.mount('#app')
})()
