import Moralis from 'moralis-v1'
import ABIMAIN from '@/artifacts/ABIMAIN.json'
import ABI721 from '@/artifacts/ERC721.json'
import ABIFACTORY from '@/artifacts/NFTFACTORY.json'
import ABINFTART from '@/artifacts/NFTArt.json'
import { CONTRACT_ADDRESS, NET_NAME, EXPLORER_ADDRESS, EXPLORER_TOKEN, EXPLORER_TX, FACTORY_ADDRESS } from '@/config'
import { creaHash } from '@/plugins/utils'

export const isOperator = async (userAddress) => {
  try {
    const options = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'isOperator',
      abi: ABIMAIN,
      params: {
        account: userAddress.toString()
      }
    }
    return await Moralis.executeFunction(options)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isMinter = async (userAddress) => {
  try {
    const options = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'isMinter',
      abi: ABIMAIN,
      params: {
        account: userAddress.toString()
      }
    }
    return await Moralis.executeFunction(options)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isAdmin = async (userAddress) => {
  try {
    const options = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'isAdmin',
      abi: ABIMAIN,
      params: {
        account: userAddress.toString()
      }
    }
    return await Moralis.executeFunction(options)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getCreatedTokens = async () => {
  try {
    const options = {
      contractAddress: FACTORY_ADDRESS,
      functionName: 'allTokens',
      abi: ABIFACTORY
    }
    return await Moralis.executeFunction(options)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getNFTCustoms = async (contractAddress, userAddress) => {
  const options = {
    contractAddress: contractAddress,
    functionName: 'npm ',
    abi: ABINFTART,
    params: {
      _owner: userAddress.toString()
    }
  }
  const items = []
  const inWallet = await Moralis.executeFunction(options)
  if (inWallet.length > 0) {
    for (const tokenId of inWallet) {
      const options_name = {
        contractAddress: contractAddress,
        functionName: 'name',
        abi: ABINFTART
      }
      const name = await Moralis.executeFunction(options_name)
      const options_symbol = {
        contractAddress: contractAddress,
        functionName: 'symbol',
        abi: ABINFTART
      }
      const symbol = await Moralis.executeFunction(options_symbol)
      const options = {
        contractAddress: contractAddress,
        functionName: 'tokenURI',
        abi: ABINFTART,
        params: {
          tokenId: Number(tokenId)
        }
      }
      const tokenURI = await Moralis.executeFunction(options)
      const metadata = await loadMetadata(tokenURI)
      const customToken = {
        name: name,
        token_id: String(Number(tokenId)),
        contract_type: 'ERC721',
        amount: '1',
        metadata: metadata,
        token_address: contractAddress,
        owner_of: userAddress,
        symbol: symbol,
        token_uri: tokenURI
      }

      customToken.image_src = await getImageURL(customToken)
      items.push(customToken)
    }
  }
  return items
}

export const getNFTNelWallet = async (userAddress) => {
  try {
    let options = { chain: NET_NAME, address: userAddress }
    const allNFT = await Moralis.Web3API.account.getNFTs(options)
    if (allNFT.cursor != null) {
      let cursor = allNFT.cursor
      do {
        options = { chain: NET_NAME, address: userAddress, cursor: cursor }
        const data = await Moralis.Web3API.account.getNFTs(options)
        if (data.result.length > 0) {
          allNFT.result = allNFT.result.concat(data.result)
        }
        cursor = data.cursor
      } while (cursor != null)
    }
    /* const created = await getCreatedTokens()
    for (const creato of created) {
      const found = await getNFTCustoms(creato, userAddress)
      allNFT.result = allNFT.result.concat(found)
    }
    */
    console.log(allNFT)
    return allNFT
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getAllNFTinDB = async (approved = true, sold = false, visibili = true, cercaAsta = '', cercaLotto = '', offset = 0) => {
  try {
    if (cercaAsta == '' && cercaLotto == '') {
      const query = new Moralis.Query('Item')
      query.descending('updatedAt')
      query.equalTo('approved', approved)
      query.equalTo('visibile', visibili)
      query.equalTo('sold', sold)
      query.skip(offset)
      const result = await query.find()
      return result
    } else {
      const filterQuery = new Moralis.Query('Item')
      filterQuery.equalTo('approved', approved)
      filterQuery.equalTo('visibile', visibili)
      filterQuery.equalTo('sold', sold)
      const astaQuery = new Moralis.Query('Item')
      astaQuery.equalTo('idAsta', parseInt(cercaAsta))
      const numeroQuery = new Moralis.Query('Item')
      numeroQuery.equalTo('numeroLotto', cercaLotto)

      const mainQuery = Moralis.Query.and(
        filterQuery,
        Moralis.Query.or(astaQuery, numeroQuery)
      )
      mainQuery.descending('updatedAt')
      mainQuery.skip(offset)
      const result = await mainQuery.find()
      return result
    }
  } catch (err) {
    console.error(err)
    return []
  }
}

export const getNFTInStore = async (userAddress) => {
  try {
    const query = new Moralis.Query('Item')
    query.equalTo('owner', userAddress)
    const result = await query.find()
    return result
  } catch (err) {
    console.error(err)
    return []
  }
}

export const setAggiudicazione = async (tokenAddress, tokenId, buyerAddress) => {
  try {
    const query = new Moralis.Query('Item')
    query.equalTo('token_address', tokenAddress)
    query.equalTo('token_id', tokenId)
    const result = await query.first()
    if (result == undefined) {
      return false
    }
    result.set('buyer', buyerAddress)
    await result.save()
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getRecordFromDB = async (nft) => {
  const query = new Moralis.Query('Item')
  query.equalTo('token_address', nft.token_address)
  query.equalTo('token_id', nft.token_id)
  const result = await query.first()
  if (result == undefined) {
    const Item = Moralis.Object.extend('Item')
    const item = new Item()
    return item
  }
  return result
}

export const transferNFT = async (item, destination_wallet, transactionOBJ) => {
  try {
    const token_id = item.get('token_id')
    const token_address = item.get('token_address')
    const options = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'transferItem',
      abi: ABIMAIN,
      params: {
        _nftAddress: token_address,
        _tokenId: token_id,
        _toAddress: destination_wallet

      }
    }
    const transaction = await Moralis.executeFunction(options)
    transactionOBJ.hash = transaction.hash ?? ''
    const receipt = await transaction.wait()
    if (receipt.status) {
      item.set('sold', true)
      item.set('approved', false)
      item.set('buyer', destination_wallet)
      await item.save()
      return true
    } else {
      return false
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

export const visibilitaDB = async (item, visibile = false) => {
  try {
    item.set('visibile', visibile)
    await item.save()
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

export const verificaApprovazione = async (nft) => {
  const checkOption = {
    contractAddress: nft.get('token_address'),
    functionName: 'getApproved',
    abi: ABI721,
    params: {
      tokenId: nft.get('token_id')
    }
  }
  const result = await Moralis.executeFunction(checkOption)
  const isValid = (result == CONTRACT_ADDRESS)
  nft.set('approved', isValid)
  await nft.save()
  return isValid
}

export const getDatiCarico = async (hash) => {
  const query = new Moralis.Query('Carichi')
  query.equalTo('hash', hash)
  const carico = await query.first()
  return carico
}

export const approveNFT = async (nft, status = true, transactionOBJ) => {
  try {
    const item = await getRecordFromDB(nft)
    item.set('name', nft.name)
    item.set('owner', nft.owner_of)
    item.set('token_address', nft.token_address)
    item.set('token_id', nft.token_id)
    item.set('token_uri', nft.token_uri)
    item.set('approved', !status)
    item.set('sold', false)
    item.set('visibile', true)
    item.set('buyer', '')
    item.set('image', await nft.image_src)
    const hash = creaHash(nft)
    item.set('hash', hash)
    const carico = await getDatiCarico(hash)
    if (carico) {
      item.set('idAsta', carico.get('idAsta') ?? null)
      item.set('idGestionale', carico.get('idGestionale') ?? null)
      item.set('numeroLotto', carico.get('numeroLotto' ?? null))
    }
    await item.save()

    const autorizzeOption = {
      contractAddress: nft.token_address,
      functionName: 'approve',
      abi: ABI721,
      params: {
        to: status == true ? CONTRACT_ADDRESS : '0x0000000000000000000000000000000000000000',
        tokenId: nft.token_id
      }
    }
    const transaction = await Moralis.executeFunction(autorizzeOption)
    transactionOBJ.hash = transaction.hash ?? ''
    const receipt = await transaction.wait()
    if (receipt.status) {
      item.set('approved', status)
      await item.save()
      return true
    } else {
      return false
    }
  } catch (err) {
    console.error(err)
    return false
  }
}
// Carico i metadata dal server IPFS
const loadFromIPFS = async (ipfsHash) => {
  try {
    ipfsHash = ipfsHash.replace('ipfs://', '')
    const url = `https://ipfs.io/ipfs/${ipfsHash}`
    const result = await fetch(url)
    return await result.text()
  } catch (err) {
    return ''
  }
}

// Carico i metadata da un url HTTP
const loadFromHTTP = async (url) => {
  try {
    const result = await fetch(url)
    return await result.text()
  } catch (err) {
    return ''
  }
}

export const loadMetadata = async (tokenURI) => {
  try {
    if (tokenURI.indexOf('ipfs://') == -1) {
      return await loadFromHTTP(tokenURI)
    } else {
      return await loadFromIPFS(tokenURI)
    }
  } catch (err) {
    console.log('Missing Meta')
    return ''
  }
}

export const getImageURL = async (item) => {
  try {
    let meta = null
    if (item.metadata == undefined || item.metadata == null) {
      const metadata = await loadMetadata(item.token_uri)
      meta = JSON.parse(metadata)
    } else {
      meta = JSON.parse(item.metadata)
    }

    if (meta == null || meta.image == undefined) {
      return ''
    }

    if (meta.image.indexOf('https') == 0) {
      return meta.image
    } else {
      const ipfsHash = meta.image.replace('ipfs://', '')
      const url = `https://ipfs.io/ipfs/${ipfsHash}`
      return url
    }
  } catch (err) {
    return ''
  }
}

export const itemAddress = (address, type = 'address') => {
  if (address == '') {
    return ''
  }
  switch (type) {
    case 'token':
      return EXPLORER_TOKEN + address
    case 'tx':
      return EXPLORER_TX + address
    default:
      return EXPLORER_ADDRESS + address
  }
}

export const shortAddress = (address) => {
  if (address == undefined || address == '') {
    return ''
  }
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  const match = address.match(truncateRegex)
  if (!match) return ''
  return `${match[1]}…${match[2]}`
}
