<template>
  <svg
    id="Livello_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 304.5 78"
    style="enable-background:new 0 0 304.5 78;fill:#631434; "
    xml:space="preserve"
    width="360"
  >
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                class="st0"
                d="M288.8,21.8c3.2,0,5.8-2.6,5.8-5.8s-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8S285.6,21.8,288.8,21.8"
              />
              <path
                class="st0"
                d="M202.9,63.2c0.7,0,1.2,0,1.7-0.2H201C201.5,63,202.2,63.2,202.9,63.2"
              />
              <path
                class="st0"
                d="M128.5,63c0,0,0.5,0.2,1.5,0h-2C128.1,63,128.3,63,128.5,63"
              />
              <path
                class="st0"
                d="M274.4,63c0.2,0,0.5,0,0.7,0h-1.5C273.8,63,274,63,274.4,63"
              />
              <path
                class="st0"
                d="M168.6,63.2c1,0,2,0,2.9-0.2h-6C166.6,63.2,167.5,63.2,168.6,63.2"
              />
              <path
                class="st0"
                d="M238.3,63.2c0.7,0,1.2,0,1.9-0.2h-3.7C236.9,63,237.6,63.2,238.3,63.2"
              />
              <path
                class="st0"
                d="M212.6,73.9c0,0-0.7,4.6,6.8,0c0,0,2.6-1.2,5.1-7.7c0,0,0.9-1.7,2-3.4h-14.1
						C212.6,67.3,212.6,73.9,212.6,73.9"
              />
              <path
                class="st0"
                d="M105.5,63c0.5,0,0.9,0,1.4-0.2h-2.7C104.6,63,105,63,105.5,63"
              />
              <path
                class="st0"
                d="M178.2,43.4c0,8.2-4.3,14.8-9.7,14.8c-5.3,0-9.7-6.6-9.7-14.8s4.3-14.8,9.7-14.8c0.7,0,1.2,0,1.5,0.2
						l0.9,0.2c0,0,2.4,0.7,1.2,2c-1.2,1.4-2,2.7-1.7,4.4c0.3,1.5,1.2,3.1,3.7,3.2c2.4,0.2,3.6,1.2,3.7,2.2c0,0,0.2,1,0.3,2.2
						 M133.4,56.6c0,0-1.4,0.7-3.6,0.7h-0.5c-4.6,0-7.5-5.8-7.7-13.3c-0.3-7.5,3.4-12.6,7.8-13.3h1c0,0,6.3-0.9,6.8,4.3v0.7v15.2
						v2.4C137.3,53,137.7,55,133.4,56.6z M57,57.9h-0.3c-4.4,0-6.8-3.2-6.8-6.8c0-3.7,2.7-7,7-7h0.3c0,0,4.1,0,5.3,1
						c0.7,0.7,0.7,1.2,0.7,1.2v0.3v5.8C63.3,52.3,63.4,57.7,57,57.9z M15.8,60.6V20v-2c1-2,3.7-3.6,6.8-3.6c2,0,3.9,0.7,5.3,1.9
						l0.2,0.2c2.9,2.4,4.9,7,4.9,12.3c0,7.8-4.4,14.1-9.9,14.1c-0.2,0-0.5,0-0.7,0c0,0-2.9-0.7-2.9,1.4c0,0-0.3,1.4,1.5,1.7
						c0.3,0,0.5,0,0.5,0.2c3.1,0.5,6.8,0.2,10.7-0.9c4.6-1.2,8.5-3.2,11.1-5.8c0,0,1.5-1.9,3.1-3.6c1.5-1.7,3.6-3.7,7.5-4.4
						s5.4-0.5,7.8,1.4c1.5,1.2,2.4,2.9,2,5.3c-0.2,2.4-1.9,2.9-1.9,2.9s-0.3,0.3-1.5-0.2c-1.2-0.5-4.3-1-5.8-1
						c-1.5,0-6.8,0.9-9.9,4.6c0,0-3.1,3.2-3.1,7.3c0,3.6,0.7,5.4,3.4,8.2c2.7,2.9,6.6,3.2,10.2,2.9c1.7-0.2,5.6-2.2,6.3-3.1
						c0.7-0.9,2.2-0.3,2.7,0.5c0.7,0.9,3.4,4.1,7.5,2.6c4.1-1.5,5.8-2.7,5.8-2.7s1.9-1.4,1.9,1.4v0.5c0,0.7,0.3,1.2,0.9,1.2h6.1
						c0.5-0.2,0.9-0.7,0.9-1.2v-0.2v-1.4V39c0,0,0.2-3.2,3.1-5.4c2.2-1.7,4.4-2,6.3-0.9c2,1.2,2.2,2.4,2.2,3.9
						c-0.2,1.9,0,21.1,0,21.1s0,4.3,5.1,4.9h2.7c5.6-0.9,9.2-6.5,9.2-6.5l0.3,0.5c2.9,3.6,7,5.8,11.4,6h2c1.5-0.2,3.9-0.9,6.3-3.4
						c0,0,0.5-0.5,1.4,0.2l0.2,0.3c0.9,1.4,2.4,2.7,5.8,2.7c6.5,0,10.6-6.5,10.6-6.5l0.5,0.5c0.3,0.5,0.9,0.9,1.2,1.4l0.7,0.5
						c2.6,2.2,5.6,3.7,9.2,4.3h6c8.9-1.5,15.7-9.5,15.7-19.1c0-1.7-0.2-3.1-0.7-4.6v-0.2c-0.3-1.5,7.7-4.6,7.7-4.6
						c2.2-1.2,2,2.6,2,2.6v0.3c-0.2,1.9,0,20.9,0,20.9s0,4.1,4.8,4.9h3.6c2.2-0.3,3.9-1.5,5.4-2.7c0,0,2.6-2,2.6-0.5
						c0,0.3,0,1.5,0,3.2h14.1c1-1.5,2.2-3.1,3.4-3.9c1.4-0.9,2.6,1.4,2.6,1.4c0.7,1,1.7,2,3.9,2.6h3.7c2-0.3,3.9-1.5,5.3-2.7
						l0.7-0.5c0,0,1.9-1.4,1.9,1.5v0.5c0,0.7,0.3,1,0.9,1h6.1c0.5,0,0.9-0.5,0.9-1V61V44.8v-5.4v-0.5c0,0,0.2-3.2,3.2-5.4
						c2.4-1.7,4.6-2,6.6-0.9c2,1.2,2.2,2.4,2.2,3.9c0,1.9,0,4.3,0,4.3v2.7v8.2c0,3.6,0,6.3,0,6.3s0,4.6,5.6,5.1h1.5
						c2.7-0.2,5.6-1.9,7.1-3.2c0,0,1.7-1.7,2.6-0.5l0.9,1c0.9,1.4,2.2,2.6,4.9,2.7h1.7c2.6-0.2,4.9-1.5,6.5-2.9l0.9-0.7
						c3.1-2.6,2.2-3.2,2.2-3.2c-0.3-1-4.6,1.2-4.6,1.2c-4.1,1.4-4.1-3.2-4.1-3.2v-2.6v-15v-6v-1.2c0.9-2.4,0.2-3.4,0.2-3.4
						c-1-2.2-2.9,0-2.9,0s-2.7,3.9-5.1,5.3c-2.4,1.2-3.1,1-3.6,2c-0.7,1.5,1,2.6,2.2,3.6c1.4,1.2,1,2.2,1,2.2s0,7,0,7.3v1.2
						c0,0,0,6.6,0,7.7c0,1-3.2,2.2-3.2,2.2c-6,1.7-5.4-3.2-5.4-3.2V36.5l-0.2-1.9c0-1.7-0.3-6.5-5.4-9c-4.9-2.6-11.6,2.7-11.6,2.7
						l-1,1c-2.6,2.2-2,0.2-2,0.2c0.9-2.4,0.2-3.4,0.2-3.4c-1-2.2-2.9,0-2.9,0s-2.7,3.9-5.1,5.3c-2.4,1.2-3.1,1-3.6,2
						c-0.7,1.5,1,2.6,2.2,3.6c1.4,1.2,1,2.2,1,2.2V47v0.5c0,0,0,6.6,0,7.7c0,1-2.9,2.2-2.9,2.2c-5.4,1.7-4.9-3.2-4.9-3.2v-4.1v-1.5
						v-17v-2.4c0.9-2.4,0.2-3.4,0.2-3.4c-1-2.2-2.9,0-2.9,0s-2.7,3.9-5.1,5.3c-2.4,1.2-3.1,1-3.6,2c-0.7,1.5,1,2.6,2.4,3.6
						c1.4,1.2,1,2.2,1,2.2v13.6v0.9c0,0,0.2,1-1,2.2c-1.2,1.2-7.5,6.3-7.5,6.3c-2.7,2.2-2.2-0.5-2.2-0.5V34.1v-2.2
						c0,0,0-0.7,1.2-0.9h1.7h2.6c1.5,0,2.7-1.2,2.7-2.7s-1.4-2.7-2.7-2.7h-2.6h-1.7c-1.4,0-1.2-1-1.2-1V22v-9.4c0,0,0-5.1,5.1-5.3
						c0,0,4.3-0.3,6,3.7c0,0,0.3,0.5,0.2,0.9c-0.2,0.3-2,2.6-2,2.6c-0.3,0.7-0.9,1.2-0.9,2.2c0,2.9,2.6,5.3,5.8,5.3s5.8-2.4,5.8-5.3
						V16c0-0.5-0.2-1.4-0.5-1.9c-0.3-0.9-1.9-2.4-1.9-2.4l-0.2-0.2c-0.5-1.4-2.2-3.2-2.2-3.2c-2.2-3.1-6.1-5.1-10.6-5.1
						c-5.3,0-9.7,2.9-11.7,7c0,0-2,2.9-1.9,8.3v0.9c0,0,0,34.6,0,35.6c0,1-3.2,2.2-3.2,2.2c-5.1,1.9-4.9-3.2-4.9-3.2v-0.5V8.9V7.7
						c0.9-2.4,0.2-3.4,0.2-3.4c-1-2.2-2.9,0-2.9,0s-2.7,3.9-5.1,5.3c-2.4,1.2-3.1,1-3.6,2c-0.7,1.5,1,2.6,2.4,3.6
						c1.4,1.2,1,2.2,1,2.2s0,7,0,7.3v3.4c0,0,0.2,1.7-0.9,2c0,0-9.4,5.6-11.2,3.1l-0.2-0.2c-3.2-5.3-9-8.7-15.5-8.7
						c-10.2,0-18.7,8.7-18.7,19.4c0,3.1,0.7,6,1.9,8.5l0.3,0.7c0.2,0.5,0.7,1,0.9,1.5l-0.2,0.3c-1.2,2-3.4,2.4-3.4,2.4
						c-4.3,0.3-4.1-2.7-4.1-2.7s0-42.7,0-44.4V7.7c0.9-2.4,0.2-3.4,0.2-3.4c-1-2.2-2.9,0-2.9,0s-2.7,3.9-5.1,5.3
						c-2.4,1.2-3.1,1-3.6,2c-0.7,1.5,1,2.6,2.4,3.6c1.4,1.2,1,2.2,1,2.2s0,8.2,0,8.5s-0.2,2.7-2.9,1.4c-2.7-1.4-6-1.2-6-1.2
						c-9,0.3-16,8.2-16,18.4c0,3.7,0.7,7.3,2.4,10.2l0.3,0.3c-1.2,2-3.4,2.4-3.4,2.4c-4.3,0.3-4.3-2.6-4.3-2.6s0.2-18.2,0.2-20.1
						s-0.3-6.5-5.4-9c-4.9-2.6-11.6,2.7-11.6,2.7l-1,1c-2.6,2.2-2,0.2-2,0.2c0.9-2.4,0.2-3.4,0.2-3.4c-1-2.2-2.9,0-2.9,0
						s-2.7,3.9-5.1,5.3c-2.4,1.2-3.1,1-3.6,2c-0.7,1.5,1,2.6,2.2,3.6c1.4,1.2,1.2,2,1.2,2v0.5v16c0,0-1,1.4-4.1,1.4
						c-5.1,0-4.8-3.1-4.8-3.1l-0.2-19.6c-0.2-3.9-3.9-6.6-3.9-6.6c-2.9-2.2-9-1.7-11.9-0.9c-6.1,2-10.7,7.7-10.7,7.7
						c-1.2,1.4-1.9,2-3.2,1.7c-1.4-0.3,0.2-2.6,0.2-2.6c1-1.9,1.7-4.1,1.7-6.6v-0.9c0-9-8.3-16.2-18.7-16.2h-0.5
						c-8.5,0-16,2.6-19.1,6.1c0,0-1.9,1.2,0.7,2.9c1.7,1.2,1.7,4.3,1.7,4.3v37.8l0.9,1.2l7.1,0.2l1.2-0.7L15.8,60.6z"
              />
              <path
                class="st0"
                d="M290.5,63c0.2,0,0.3,0,0.5,0c0.3,0,0.9,0,1.2,0"
              />
              <path
                class="st0"
                d="M80.6,63h5.6c0.2,0,0.2,0,0.3,0h-6.1C80.5,63,80.6,63,80.6,63"
              />
              <path
                class="st0"
                d="M21.6,45.8c-0.2,0-0.2,0-0.3,0C21.4,45.8,22.4,46,21.6,45.8"
              />
              <path
                class="st0"
                d="M6.6,61v0.5c0,0.7,0.5,1.2,1,1.4h7.1c0.7-0.2,1-0.7,1-1.4v-0.2V60"
              />
              <path
                class="st0"
                d="M10.2,73.7c2,0,3.2-0.9,3.2-3.7h-2.2c0,0.9,0,2.2-1,2.2c-1.2,0-1.4-1.2-1.4-3.9s0.2-3.9,1.4-3.9
						c0.7,0,0.9,0.5,0.9,1.9h2.2c0.2-2.2-0.9-3.6-3.1-3.6c-3.6,0-3.6,2.7-3.6,5.4C6.4,71,6.4,73.7,10.2,73.7"
              />
              <path
                class="st0"
                d="M19.2,69.7h-2l1-4.6l0,0 M16.1,73.6l0.7-2.4h3.1l0.5,2.4h2.4l-2.9-10.7H17l-2.9,10.7H16.1z"
              />
              <path
                class="st0"
                d="M30.2,70.7c0-3.4-4.4-3.2-4.4-5.3c0-0.7,0.3-1.2,1.2-1.2c0.9,0,1,0.9,1,1.5h2c0.2-2.2-0.9-3.2-3.1-3.2
						c-2.7,0-3.4,1.4-3.4,3.1c0,3.2,4.4,3.2,4.4,5.3c0,0.7-0.3,1.2-1.2,1.2c-1.2,0-1.2-0.9-1.2-1.9h-2.2c-0.2,1.9,0.3,3.4,3.1,3.4
						C28.2,73.7,30.2,73.4,30.2,70.7"
              />
              <path
                class="st0"
                d="M36.4,69.7h-2l1-4.6l0,0 M33.1,73.6l0.7-2.4h3.1l0.7,2.4h2.4L36.9,63H34l-2.9,10.7h2V73.6z"
              />
              <path
                class="st0"
                d="M44,73.6h3.6c3.7,0,3.6-3.7,3.6-5.4c0-3.4-0.5-5.3-3.4-5.3H44 M46.2,64.6h1.2c1.5,0,1.5,1.7,1.5,3.7
						c0,2.7-0.3,3.7-1.7,3.7h-1V64.6z"
              />
              <polyline
                class="st0"
                points="52.4,65.9 53.9,65.9 54.9,63 52.9,63 					"
              />
              <path
                class="st0"
                d="M60.7,69.7h-2l1-4.6l0,0 M57.7,73.6l0.7-2.4h3.1l0.5,2.4h2.2l-2.9-10.7h-2.9l-2.9,10.7H57.7z"
              />
              <path
                class="st0"
                d="M71.8,70.7c0-3.4-4.4-3.2-4.4-5.3c0-0.7,0.3-1.2,1.2-1.2s1,0.9,1,1.5h2.2c0.2-2.2-0.9-3.2-3.1-3.2
						c-2.7,0-3.6,1.4-3.6,3.1c0,3.2,4.4,3.2,4.4,5.3c0,0.7-0.3,1.2-1,1.2c-1.2,0-1.2-0.9-1.2-1.9h-2.2c-0.2,1.9,0.3,3.4,3.1,3.4
						C69.7,73.7,71.8,73.4,71.8,70.7"
              />
              <polyline
                class="st0"
                points="72.5,63 72.5,64.7 75,64.7 75,73.6 77.2,73.6 77.2,64.7 79.6,64.7 79.6,63 					"
              />
              <polyline
                class="st0"
                points="86.6,73.6 86.6,72 82.8,72 82.8,68.8 86.2,68.8 86.2,67.3 82.8,67.3 82.8,64.6 86.4,64.6
						86.4,63 80.6,63 80.6,73.6 					"
              />
              <path
                class="st0"
                d="M260.1,70.2c0-1.4,0-2.4-0.9-2.4c-0.9,0-0.9,1-0.9,2.4c0,1.9,0.2,2.6,0.9,2.6
						C259.9,72.7,260.1,72,260.1,70.2 M260.1,73.7v-0.9l0,0c-0.3,0.7-0.9,0.9-1.7,0.9c-2,0-2-2.2-2-3.7c0-1.4,0-3.6,1.9-3.6
						c0.7,0,1.2,0.2,1.7,0.9l0,0v-3.7h2v10.2H260.1z"
              />
              <path
                class="st0"
                d="M260.1,70.2c0-1.4,0-2.4-0.9-2.4c-0.9,0-0.9,1-0.9,2.4c0,1.9,0.2,2.6,0.9,2.6
						C259.9,72.7,260.1,72,260.1,70.2 M260.1,73.7v-0.9l0,0c-0.3,0.7-0.9,0.9-1.7,0.9c-2,0-2-2.2-2-3.7c0-1.4,0-3.6,1.9-3.6
						c0.7,0,1.2,0.2,1.7,0.9l0,0v-3.7h2v10.2H260.1z"
              />
              <path
                class="st0"
                d="M265.3,71.5c0,0.5,0.2,1.2,0.7,1.2c1.2,0,1-1.5,1-2.4C266.2,70.5,265.3,70.3,265.3,71.5 M269.1,72.6
						c0,0.5,0,0.9,0.2,1.4h-1.9l-0.2-1l0,0c-0.3,0.7-1,1-1.9,1c-1.4,0-1.9-1-1.9-2.2c0-2.4,1.9-2.4,3.7-2.4v-0.5c0-0.7,0-1-0.9-1
						c-0.7,0-0.7,0.5-0.7,1h-1.9c0-0.9,0.3-1.4,0.7-1.7c0.5-0.3,1-0.5,1.9-0.5c2.6,0,2.9,1,2.9,2.4v3.6H269.1z"
              />
              <path
                class="st0"
                d="M265.3,71.5c0,0.5,0.2,1.2,0.7,1.2c1.2,0,1-1.5,1-2.4C266.2,70.5,265.3,70.3,265.3,71.5 M269.1,72.6
						c0,0.5,0,0.9,0.2,1.4h-1.9l-0.2-1l0,0c-0.3,0.7-1,1-1.9,1c-1.4,0-1.9-1-1.9-2.2c0-2.4,1.9-2.4,3.7-2.4v-0.5c0-0.7,0-1-0.9-1
						c-0.7,0-0.7,0.5-0.7,1h-1.9c0-0.9,0.3-1.4,0.7-1.7c0.5-0.3,1-0.5,1.9-0.5c2.6,0,2.9,1,2.9,2.4v3.6H269.1z"
              />
              <rect
                x="270.8"
                y="63.7"
                class="st0"
                width="2"
                height="10.2"
              />
              <rect
                x="270.8"
                y="63.7"
                class="st0"
                width="2"
                height="10.2"
              />
              <path
                class="st0"
                d="M277.6,65.6c0.7-0.3,1.4-0.9,1.9-1.5h1.9v9.7h-2.2v-7.5c-0.5,0.3-1,0.9-1.7,1v-1.7H277.6z"
              />
              <path
                class="st0"
                d="M277.6,65.6c0.7-0.3,1.4-0.9,1.9-1.5h1.9v9.7h-2.2v-7.5c-0.5,0.3-1,0.9-1.7,1v-1.7H277.6z"
              />
              <path
                class="st0"
                d="M286.3,68.6c0.9,0,0.9-1.2,0.9-1.7c0-0.7,0-1.7-0.9-1.7s-0.9,1.2-0.9,1.7
						C285.4,67.6,285.4,68.6,286.3,68.6 M285.6,71.5c0,0.2,0,0.5,0.2,0.7c0.2,0.2,0.3,0.3,0.5,0.3c1,0,0.9-0.9,0.9-3.1
						c-0.5,0.5-1,0.7-1.7,0.7c-1.5,0-2-1.4-2-2.7c0-1.9,0.5-3.6,2.7-3.6c2.9,0,2.9,2.4,2.9,5.1c0,2.4,0,4.9-2.9,4.9
						c-1.7,0-2.7-0.5-2.7-2.4C283.4,71.5,285.6,71.5,285.6,71.5z"
              />
              <path
                class="st0"
                d="M286.3,68.6c0.9,0,0.9-1.2,0.9-1.7c0-0.7,0-1.7-0.9-1.7s-0.9,1.2-0.9,1.7
						C285.4,67.6,285.4,68.6,286.3,68.6 M285.6,71.5c0,0.2,0,0.5,0.2,0.7c0.2,0.2,0.3,0.3,0.5,0.3c1,0,0.9-0.9,0.9-3.1
						c-0.5,0.5-1,0.7-1.7,0.7c-1.5,0-2-1.4-2-2.7c0-1.9,0.5-3.6,2.7-3.6c2.9,0,2.9,2.4,2.9,5.1c0,2.4,0,4.9-2.9,4.9
						c-1.7,0-2.7-0.5-2.7-2.4C283.4,71.5,285.6,71.5,285.6,71.5z"
              />
              <path
                class="st0"
                d="M289.7,73.7V72c3.1-4.1,3.7-4.6,3.7-5.8c0-0.5-0.2-1-0.7-1c-0.9,0-0.9,0.9-0.9,1.4h-2
						c-0.2-1.9,0.9-2.9,2.7-2.9c1.7,0,2.9,0.7,2.9,2.6c0,2.2-2.6,4.8-3.6,6h3.7v1.5H289.7z"
              />
              <path
                class="st0"
                d="M289.7,73.7V72c3.1-4.1,3.7-4.6,3.7-5.8c0-0.5-0.2-1-0.7-1c-0.9,0-0.9,0.9-0.9,1.4h-2
						c-0.2-1.9,0.9-2.9,2.7-2.9c1.7,0,2.9,0.7,2.9,2.6c0,2.2-2.6,4.8-3.6,6h3.7v1.5H289.7z"
              />
              <path
                class="st0"
                d="M299.2,70l0.2-4.8l0,0l-1.9,4.8H299.2z M296,69.7c2.4-5.6,2.4-5.6,2.4-5.6h2.6v6.1h0.9v1.5h-0.9v2H299
						v-2.2H296V69.7z"
              />
              <path
                class="st0"
                d="M299.2,70l0.2-4.8l0,0l-1.9,4.8H299.2z M296,69.7c2.4-5.6,2.4-5.6,2.4-5.6h2.6v6.1h0.9v1.5h-0.9v2H299
						v-2.2H296V69.7z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
