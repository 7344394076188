export const darkModeKey = 'darkMode'
export const styleKey = 'style'
const BASE_SCANNER_URL = 'https://etherscan.io/'
export const EXPLORER_ADDRESS = BASE_SCANNER_URL + 'address/'
export const EXPLORER_TOKEN = BASE_SCANNER_URL + 'token/'
export const EXPLORER_TX = BASE_SCANNER_URL + 'tx/'
export const INFURA_ID = '2AeQyV6WeTvnmn8V0a0iYqULoG8'
export const INFURA_SECRET = 'fff66e29d032888a972c25943718c0a4'
export const INFURA_GATEWAY = 'https://pandolfininft.infura-ipfs.io'

/*
DATI PRODUZIONE
*/
export const chainId = 1
export const NET_NAME = 'mainnet' // parseInt(1, 16)// 'mainnet'
export const CONTRACT_ADDRESS = '0xE6c2Ad271877C48b78ee696F0ed8a2C1F27BA6fD'
export const MORALIS_API = 'WnDd9bHhJyQEQkV0Gmj51ULrIU17ie2bpnry6OII'
export const MORALIS_URL = 'https://mju8jauv5fci.usemoralis.com:2053/server'
// export const MORALIS_URL = 'http://localhost:1337/server'
export const NFT_ADDRESS = '0xF12De085F87AF1319Bd4e4Ebb0A000eD1F9Ec7cA'
export const FACTORY_ADDRESS = '0x47AFf704214b0e647086A4748d2DAEc103893D69'

/*
DATI TEST

export const chainId = 3
export const NET_NAME = 'ropsten'
export const NFT_ADDRESS = '0xF12De085F87AF1319Bd4e4Ebb0A000eD1F9Ec7cA'
export const CONTRACT_ADDRESS = '0x1CfE562e5628603445B27f03B8284724219C378D'
export const MORALIS_API = 'CeInfy4bBEQFUaWJW5HxFvDaFOMbZBpWx6pjDL8Q'
export const MORALIS_URL = 'https://lrpj4jevtexw.usemoralis.com:2053/server'
export const FACTORY_ADDRESS = '0xE47B4419a38F646398A46828b32b13c2D2F2C4c9'
*/
