<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Level from '@/components/Level.vue'
import JbLogo from '@/components/JbLogo.vue'
import { CONTRACT_ADDRESS } from '@/config.js'
import { itemAddress } from '@/plugins/api'
const store = useStore()

const year = computed(() => new Date().getFullYear())

const isFooterBarVisible = computed(() => !store.state.isFullScreen)
</script>

<template>
  <footer
    v-show="isFooterBarVisible"
    class="bg-white py-2 px-6 dark:bg-gray-900/70"
  >
    <level>
      <div class="text-center md:text-left">
        <b>&copy; {{ year }}, <a
          href="https://www.thetis.tv/"
          target="_blank"
        >Thetis srl</a></b> -
        <a
          :href="itemAddress(CONTRACT_ADDRESS)"
          target="_blank"
          class="text-blue-600"
        >  Pandolfini Escrow Contract</a>
      </div>
      <div class="md:py-2">
        <a href="https://www.pandolfini.it/">
          <jb-logo class="w-auto h-8 md:h-6" />
        </a>
      </div>
    </level>
  </footer>
</template>
